import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { BusinessCenter } from "@mui/icons-material";
import { Psychology } from "@mui/icons-material";
import { HandshakeRounded } from "@mui/icons-material";

//
function Services() {
  return (
    <section className="agr-section services">
      <div className="container">
        <div className="row justify-content-center mb-5 pb-2">
          <div className="col-md-8 text-center heading-section">
            <span className="subheading">Services</span>
            <h2 className="mb-4">Our Services</h2>
            <p>
              With our dynamic and innovative nature, we offer wide range of
              services as listed below.
            </p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="pills w-100">
            <BasicTabs />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className=""
          aria-label="agrico hub services"
        >
          <Tab label="Skills Development" {...a11yProps(0)} />
          <Tab label="Business Incubation & Acceleration" {...a11yProps(1)} />
          <Tab label="Ecosystem Development" {...a11yProps(2)} />
          <Tab label="Access to Finance & Market Support" {...a11yProps(3)} />
          <Tab label="Ecosystem Development" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <Psychology />
          </div>
          <div className="text-content w-50">
            <h3 className="title text-uppercase"> SKILLS DEVELOPMENT</h3>
            <p>
              We provide essential training and capacity-building programs
              designed to enhance the skills of entrepreneurs, business owners,
              and employees in key areas such as:
            </p>
            <ul className="cadet">
              <li>• Digital Literacy & Tools</li>
              <li>• Business Management & Strategy</li>
              <li>• Financial Literacy & Planning</li>
              <li>• Leadership & Team Development</li>
              <li>• Marketing & Branding Strategies</li>
            </ul>
            These programs are designed to equip individuals and teams with the
            skills they need to adapt to the modern business landscape and drive
            sustainable growth.
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-dropper"></span>
            </div>
          </div>
          <div className="text-content w-50">
            <h3 className="title text-uppercase">
              Business Incubation & Acceleration
            </h3>
            <p>
              We support early-stage and growth-stage businesses with the
              guidance, mentorship, and resources needed to scale successfully.
              Our incubation and acceleration services include:
            </p>
            <ul className="cadet">
              <li>• Business Coaching & Mentorship</li>
              <li>• Product Development & Market Fit Strategies</li>
              <li>• Operational Efficiency Improvements</li>
              <li>• Go-to-Market Strategy Development</li>
              <li>
                • Networking Opportunities with Investors and Industry Experts
              </li>
            </ul>
            Through tailored incubation and acceleration programs, we help
            businesses overcome key challenges and position themselves for
            long-term success.
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <HandshakeRounded />
          </div>
          <div className="text-content w-50">
            <h3 className="title text-uppercase">Ecosystem Development</h3>
            <p>
              We aim to foster a thriving business ecosystem that supports
              innovation, collaboration, and sustainable growth. Our ecosystem
              development initiatives include:
            </p>
            <ul className="cadet">
              <li>• Facilitating Industry Partnerships & Collaborations</li>
              <li>
                • Organizing Networking Events, Workshops, and Conferences
              </li>
              <li>• Creating Platforms for Business Leaders to Connect</li>
              <li>
                • Advocating for Policy Changes that Benefit Local Entrepreneurs
              </li>
            </ul>
            <p className="text">
              By developing a robust entrepreneurial ecosystem, we create a
              supportive environment where businesses can thrive, collaborate,
              and innovate.
            </p>
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <div className="icon d-flex justify-content-center align-items-center">
              <BusinessCenter />
            </div>
          </div>
          <div className="text-content w-50">
            <h3 className="title text-uppercase">
              Access to Finance & Market Support
            </h3>
            <p>
              We bridge the gap between businesses and funding opportunities,
              connecting entrepreneurs with local and international investors,
              financial institutions, and grant providers. Our access to finance
              and market support includes:
            </p>
            <ul className="cadet">
              <li>• Financial Planning & Access to Capital</li>
              <li>• Grant and Loan Application Assistance</li>
              <li>• Investor Matching and Networking</li>
              <li>• Market Research & Expansion Strategies</li>
              <li>• Export Readiness and Market Linkages</li>
            </ul>
            <p className="text">
              With these services, we help businesses secure the financial
              resources they need to grow and reach wider markets.
            </p>
          </div>
        </article>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <article className="bg-light service-content-wrapper">
          <div className="icon-wrapper">
            <div className="icon d-flex justify-content-center align-items-center">
              <span className="flaticon-first-aid-kit"></span>
            </div>
          </div>
          <div className="text-content w-50">
            <h3 className="title text-uppercase">
              NETWORKING AND COMMUNITY EVENTS
            </h3>
            <p>
              We organize a diverse range of events, meetups, and networking
              sessions to cultivate connections among entrepreneurs, potential
              collaborators, and investors within a nurturing community.
            </p>
          </div>
        </article>
      </CustomTabPanel>
    </Box>
  );
}
