import React from "react";
import Slider from "./Banner/Slider";
import Footer from "./Footer";
import MainContent from "./MainContent";

export default function Body() {
  return (
    <div
      className="body"
      onScroll={(event) => {
        var scroll = event.target.scrollTop;
        var header_area = document.querySelector(".header_area");

        if (scroll > 200) {
          header_area.classList.add("navbar_fixed");
        } else {
          header_area.classList.remove("navbar_fixed");
        }
        // console.log(event.target.innerHeight);
      }}
    >
      <section className="banner-section">
        <Slider />
      </section>
      <section className="main-body">
        <MainContent />
      </section>
      <section className="footer-section">
        <Footer />
      </section>
    </div>
  );
}
