import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import edwin from "../../../images/testimonial-images/edwin.jpg";
import mike from "../../../images/testimonial-images/mike.jpg";
import rukaya from "../../../images/testimonial-images/rukaya.jpg";
import isaac from "../../../images/testimonial-images/isaac.jpg";
import { Avatar } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

//left and right button for prev and next carousel
const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
    </div>
  );
};

export default function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    AnimationSpeed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="">
      <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
        <Container
          profilePicture={mike}
          content="Before enrolling on a free web development course at Agrico Hub, I had zero or no knowledge in web development. But after this course, I have develop a website for my startup which I have started earning income"
          name="Akwesi Michael,"
          role={"Former National Web App Training Trainee"}
        ></Container>
        <Container
          profilePicture={rukaya}
          content="I enrolled in a free Mobile App traning course in the year 2021. After this we were made to develop a
           business concept with the skills aqcuired, after which we were incubated for 3 months to develop our ideas to a viable business. Thank you Agrico Hub staff and management for this guidance and support."
          name="Abdallah Rukaya,"
          role={"Former National Mobile App Program Trainee"}
        ></Container>
        <Container
          profilePicture={edwin}
          content="I am National Acceleration Program beneficiary. Through the program I was given business support through business coaching, business incubation and financial support. "
          name={"Edwin Osei,"}
          role={"Natioanal Acceleration Program Beneficiary"}
        ></Container>
        <Container
          profilePicture={isaac}
          content="Thank you Agrico Hub for your guidance and support throughout the Escape Acceleration Program. By God’s grace, and with the knowledge and skills I gained from the program, my startup has seen remarkable progress.

Thanks to the expertise and resources shared during the acceleration program, I have successfully secured several grants. I am proud to share that I won the GCIC Green Business Competition, receiving €10,000. This grant will enable me to purchase essential machinery for my mushroom and charcoal briquette business, taking it to new heights.

Additionally, I was awarded a $5,000 grant from the Tony Elumelu Foundation and have been selected as a mentor for the 2025 Entrepreneurship Programme. This opportunity will allow me to guide other entrepreneurs and help them secure their own grants, just as I did.

Moreover, I am glad to announce that my startup also received a grant from the UNDP Young African Innovators program, further solidifying our growth trajectory.

None of these would have been possible without the support and expertise from Agrico Hub.
"
          name={"Banewel Isaac,"}
          role={"CEO of Banewel Farming Enterprise"}
        ></Container>
      </Slider>
    </section>
  );
}

const Container = ({ profilePicture, content, name, role }) => {
  return (
    <div className="testimonial-container">
      <div className="avatar mb-2">
        <Avatar
          src={profilePicture}
          style={{
            width: 120,
            height: 120,
            border: "5 solid #f8f8f8",
          }}
        />
      </div>
      <p className="slider-text">{content}</p>
      <div className="name-title ">
        <h2
          className="text-uppercase"
          style={{ fontWeight: 800, fontSize: "0.8rem" }}
        >
          {name}
          <span
            className="text-primary pl-2 text-uppercase "
            style={{ fontWeight: 600 }}
          >
            {role}
          </span>
        </h2>
      </div>
    </div>
  );
};
