import React, { useEffect } from "react";
import { useState } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
//import react router
import { Link, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";

function Header() {
  const [stickyNavActive, setStickyNavActive] = useState(false);
  const [navBarToggler, setNavBarToggler] = useState(true);
  const [hideArrow, setHideArrow] = useState(false);

  const location = useLocation();
  const menuActive = (path) => {
    return location.pathname === path ? "active nav-link" : "nav-link";
  };
  useEffect(() => {
    var arrow = document.querySelector(".slider-container .arrows");
    // if arrow is null don't render anything just return
    if (arrow === null) {
      return;
    }
    if (hideArrow) {
      arrow.style.display = "none";
    } else {
      arrow.style.display = "inherit";
    }
  }, [hideArrow]);

  // scroll

  useEffect(() => {
    /// scroll event function
    const getScroll = () => {
      var scroll = window.scrollY;
      if (scroll > 750) {
        setStickyNavActive(true);
      } else {
        setStickyNavActive(!true);
      }
    };
    //add event listener to listen to scroll Event
    window.addEventListener("scroll", getScroll);
    // clean event listeners
    return () => window.removeEventListener("scroll", getScroll);
  });

  return (
    <div>
      <header id="header">
        {/* First NavBar*/}
        <nav className="navbar agr__nav-info  nav_big-screen py-4 navbar-expand-lg agr_navbar navbar-light bg-light flex-row">
          <div className="container">
            <div className="row no-gutters d-flex align-items-start align-items-center px-3 px-md-0">
              <div className="col-lg-2 pr-4 align-items-center">
                <Link to={"/"} className="navbar-brand">
                  <img src={Logo} alt="Logo" height={"40px"} />
                </Link>
              </div>
              <div className="col-lg-10 d-none d-md-block">
                <div className="row d-flex">
                  <div className="col-md-4 pr-4 d-flex topper align-items-center">
                    <div className="icon bg-white mr-2 d-flex justify-content-center align-items-center">
                      <span className="icon-map"></span>
                    </div>
                    <span className="text">
                      HN 522, Vatican City Avenue, Techiman Metropolitan – Bono
                      East Region, Ghana
                    </span>
                  </div>
                  <div className="col-md pr-4 d-flex topper align-items-center">
                    <div className="icon bg-white mr-2 d-flex justify-content-center align-items-center">
                      <span className="icon-paper-plane"></span>
                    </div>
                    <span className="text">Email: info@agricohubgh.com</span>
                  </div>
                  <div className="col-md pr-4 d-flex topper align-items-center">
                    <div className="icon bg-white mr-2 d-flex justify-content-center align-items-center">
                      <span className="icon-phone2"></span>
                    </div>
                    <p
                      className="text"
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      <span> Phone:</span>
                      <span>
                        +233 32 249 8877 <br />
                        +233 24 401 6046
                      </span>
                    </p>
                  </div>
                  <div>
                    <button className="nav-actions-btn" aria-label="Profile">
                      <a href="/auth" title="Login">
                        <AccountCircleOutlinedIcon
                          className="icon"
                          color="action"
                        />
                        <span>Profile</span>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* Second NavBar*/}
        <nav
          className={`${
            stickyNavActive
              ? "navbar navbar-expand-lg navbar-dark bg-dark agr-navbar-light header_area navbar_fixed"
              : "navbar navbar-expand-lg navbar-dark bg-dark agr-navbar-light header_area"
          }`}
          id="agr-navbar"
          style={{
            padding: "1rem 3rem",
          }}
        >
          <div className="container d-flex align-items-center">
            <button
              className="navbar-toggler"
              id="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#agr-nav"
              aria-controls="agr-nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                // set the state of navbar collapse as active/show using usestate,
                setNavBarToggler(!navBarToggler);
                return setHideArrow(!hideArrow);
              }}
              style={{
                padding: "0.6rem 0.4rem",
              }}
            >
              <span className="oi oi-menu"></span> Menu
            </button>
            <div className="mobile-auth-btn">
              <button className="nav-actions-btn" aria-label="Profile">
                <a href="/auth" title="Login">
                  <AccountCircleOutlinedIcon className="icon" color="action" />
                  <span>Login</span>
                </a>
              </button>
            </div>
            <div
              className={`${
                navBarToggler === false
                  ? "collapse navbar-collapse show"
                  : "collapse navbar-collapse"
              }`}
              id="agr-nav"
            >
              <ul className="navbar-nav mr-auto font-weight-bold">
                <li className="nav-item ">
                  <Link to="/" className={`pl-0 ${menuActive("/")}`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className={`${menuActive("/about")}`}>
                    About
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/project" className={`${menuActive("/project")}`}>
                    Projects
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link to="/gallery" className={`${menuActive("/gallery")}`}>
                    Gallery
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blog" className={` ${menuActive("/blog")}`}>
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className={`${menuActive("/contact")}`}>
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={`${menuActive(
                      "#"
                    )} navbar-link dropdown-btn dropdown-toggle`}
                  >
                    Special Initiatives
                    <div className="dropdown-content">
                      <ul
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <li>
                          <Link
                            to={"/pramaso-cafe"}
                            className={`${menuActive("/pramaso-cafe")}`}
                          >
                            Pramaso Cafe Lounge
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/startups-cooperative"}
                            className={`${menuActive("/startups-cooperative")}`}
                          >
                            Startups Cooperative Credit Union
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/ab-studio"}
                            className={`${menuActive("/ab-studio")}`}
                          >
                            Abrafi Digital Studio
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </li>
              </ul>
              {/* Book appointment button */}
              <div className=" order-lg-last mb-0 book__appointment-btn">
                <Link
                  to="/applynow"
                  aria-details="book-appointment"
                  className="btn-outline-primary py-2 pb-1 px-3 center font-weight-bold"
                >
                  Apply For Our Programs
                </Link>
              </div>
            </div>
          </div>
        </nav>
        {/* End Second NavBar*/}
      </header>
      {/* End of Header */}
    </div>
  );
}

export default Header;
