import React from "react";
import TrImage from "../../images/banner-slider__image/visit_to_mce.jpg";

function AboutText() {
  return (
    // <section className="agr-section container">
    <section className="">
      <div className="about-container bg-light mt-[3rem]">
        <div className="row justify-content-center mb-5 pb-2">
          <div className=" text-center heading-section pt-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Who We Are</span>
            </h3>
            <h2 className="mb-4">About Us</h2>
            <div className="abt-content pb-5">
              <div className="image">
                <img src={TrImage} alt="Training Pictures" />
              </div>
              <div className="text abt-text">
                <p>
                  <span className="header text-info">Agrico Hub </span>
                  is a Business and Digital Innovation Hub dedicated to
                  empowering early-stage entrepreneurs and Small & Growing
                  Businesses (SGBs) in Agribusiness, Trade, Technical and
                  Vocational services, and related industries. We play a pivotal
                  role in fostering entrepreneurship and driving Digital
                  Transformation through innovative business development
                  strategies and cutting-edge digital technology solutions.
                </p>
                <p>
                  <strong>At AgriCo Hub,</strong> we catalyze technological
                  innovation, build entrepreneurial capacity, and enhance
                  digital skills within communities. By offering tailored and
                  sustainable support, we empower Agripreneurs, innovators, and
                  Small and Growing Businesses to excel in today’s competitive
                  and rapidly evolving market.
                </p>
                <p>
                  <strong>The Hub</strong> has implemented several
                  Entrepreneurship, Business Development and Digitalization
                  programs in partnership with local and international partners
                  including GIZ, Plan Ghana, SE Ghana, Ghana Hubs Network,
                  Reach4Change, Ghana Enterprises Agency, Ghana Chamber of
                  Entrepreneurs with Disability, UNPD Ghana, NYA, Ghana Tech
                  Lab/MasterCard Foundation and Soronko Academy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutText;
