import George from "../../images/staff_images/george.jpg";
import Justice from "../../images/staff_images/justice.jpg";
import David from "../../images/staff_images/david.jpeg";
import Lute from "../../images/staff_images/lute.jpg";
import Abigail from "../../images/staff_images/Abigail.jpg";
import Eunice from "../../images/staff_images/Eunice.jpg";
import Janet from "../../images/staff_images/janet.jpg";
import Victoria from "../../images/staff_images/victoria.jpg";
import Adubea from "../../images/staff_images/adubea.jpg";

import Testimonial from "./Testimony/Testimonial.jsx";
import AboutText from "./AboutText.jsx";
import Services from "./Services.jsx";
import MissionVission from "./MissionVision.jsx";
import Partners from "./Partners.jsx";

function MainContent() {
  return (
    <div className="main-content">
      <AboutText />
      <Services />
      <MissionVission />
      {/* --- staff section ---*/}
      <section className="our-staff agr-section agr-no-pt">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-8 text-center heading-section ">
              <h3 className="fw-bolder fw-bold">
                <span className="header text-info">Staffs</span>
              </h3>
              <h2 className="mb-4">Meet Our Qualified Staffs</h2>
              <p>
                The Hub parades a team of qualified professionals and technical
                support staff who have over 10 years' experience in
                professional, vocational, and technical training in youth
                entrepreneurship development, IT innovations, Agribusiness and
                related enterprise areas.
              </p>
            </div>
          </div>
          {/*Display list of staff */}
          <div className="row">
            {/* Staff Goerge */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={George}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Goerge Antwi-Boasiako</h3>
                  <span className="position mb-2">C.E.O</span>
                  <div className="faded">
                    <p>
                      George K. Antwi is the visionary leader of AgriCo Hub,
                      serving as its Chief Executive Officer. Under his
                      guidance, the organization has flourished, driving
                      impactful initiatives and fostering innovation in
                      agriculture and digital skills development. His leadership
                      inspires growth, collaboration, and sustainable progress
                      across all operations.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Eunice */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Eunice}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Eunice Baah</h3>
                  <span className="position mb-2">Hub Manager</span>
                  <div className="faded">
                    <p>
                      Hub Maanager at Agrico Hub, Mentor and a Lecture with
                      strong leadership and communication skills.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff David */}
            <div className="col-md-6 col-lg-3">
              <div className="staff card-img-top profile">
                <div className="img-wrap d-flex align-items-stretch">
                  <img className="img align-self-stretch " src={David} alt="" />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Mr. David D. Yeboah</h3>
                  <span className="position mb-2">
                    Business Development Officer
                  </span>
                  <div className="faded">
                    <p>
                      Business Developer at Agrico Hub, Startup Coach, Mentor
                      and a Design Thinking practitioner with a background in
                      Accounting and Fundraising.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Lute */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <a href="https://yakubulute.netlify.app/">
                    <img
                      className="img align-self-stretch "
                      src={Lute}
                      alt=""
                    />
                  </a>
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">
                    {" "}
                    <a href="https://yakubulute.netlify.app/">Yakubu Lute</a>
                  </h3>
                  <span className="position mb-2">
                    <a href="https://yakubulute.netlify.app/">Technical Lead</a>
                  </span>
                  <div className="faded">
                    <p>
                      Lute is the Technical Lead at AgriCo Hub, a Digital Media
                      or Tech Trainer and a seasoned Senior Software Engineer
                      with expertise in technologies like React, Vue, Python,
                      Flutter, Node.js, and Figma. With a strong foundation in
                      both frontend and backend development, Lute drives the
                      technical strategy and oversees the implementation of
                      innovative solutions at AgriCo Hub. His diverse skill set
                      enables him to create user-friendly applications and
                      manage complex systems, ensuring the organization stays
                      ahead in digital innovation.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff  Victoria*/}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Victoria}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Yabah Victoria </h3>
                  <span className="position mb-2">TVET Coordinator</span>
                  <div className="faded">
                    <p>
                      Victoria is the TVET Coordinator at AgriCo Hub,
                      specializing in food preparation, soap making, and
                      technical and vocational education and training (TVET).
                      She plays a vital role in equipping individuals with
                      practical skills, fostering entrepreneurship, and
                      empowering communities through hands-on training programs.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Adubea */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Adubea}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Abigail Adubea</h3>
                  <span className="position mb-2">Relationship Manager</span>
                  <div className="faded">
                    <p>
                      Abigail focuses on building strong client relationships,
                      understanding their needs, and delivering tailored
                      financial solutions. Abigail’s work ensures client
                      satisfaction, drives revenue growth, and fosters customer
                      loyalty.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Justice */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Justice}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Tanye Isaac</h3>
                  <span className="position mb-2">Media Lead</span>
                  <div className="faded">
                    <p>
                      Tanye is responsible for creating visually compelling
                      content that amplifies the organization’s message and
                      brand identity. With a keen eye for detail and creativity,
                      Tanye ensures AgriCo Hub’s media and communication efforts
                      remain impactful and engaging across various platforms.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Abigail Anane Frimpong */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img
                    className="img align-self-stretch "
                    src={Abigail}
                    alt=""
                  />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Abigail Anane Frimpomaa</h3>
                  <span className="position mb-2">Office Assistant</span>
                  <div className="faded">
                    <p>
                      Abigail plays a key role in ensuring the smooth operation
                      of daily activities. She supports administrative tasks,
                      and assist team members to enhance efficiency. With a
                      strong work ethic and attention to detail, Abigail
                      contributes significantly to creating a productive and
                      professional workplace environment.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* Staff Janet Ababio */}
            <div className="col-md-6 col-lg-3">
              <div className="staff">
                <div className="img-wrap d-flex align-items-stretch">
                  <img className="img align-self-stretch " src={Janet} alt="" />
                </div>
                <div className="text pt-3 text-center">
                  <h3 className="staff-name">Effah-Ababio Janet</h3>
                  <span className="position mb-2">Community Associate</span>
                  <div className="faded">
                    <p>
                      Janet serves as the Community Associate at AgriCo Hub,
                      where she focuses on building and nurturing relationships
                      with local communities. Her role involves engaging
                      stakeholders, supporting community initiatives, and
                      fostering collaboration to drive AgriCo Hub's mission of
                      empowering individuals and promoting sustainable
                      development.
                    </p>
                    <ul className="agr-social text-center">
                      <li className="">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-google-plus"></span>
                        </a>
                      </li>
                      <li className="">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section Testimonial */}
      <section className="testimonials">
        <section className="agr-section testimony-section bg-light">
          <div className="container">
            <div className="row justify-content-center pb-2">
              <div className="col-md-8 text-center heading-section ">
                <span className="subheading">Testimonials</span>
                <h2 className="mb-4">
                  Our Trainees/Stakeholders Say About Us{" "}
                </h2>
                <p>
                  With our inspiring training programs, dynamic and innovative
                  training environments, experienced trainers and deddicated
                  staffs our trainees are always satisfied with our services.
                  This is what they have to say about Agrico Hub GH services.
                </p>
              </div>
            </div>
            <div className="">
              <Testimonial />
            </div>
          </div>
        </section>
      </section>
      <Partners />
    </div>
  );
}

export default MainContent;
