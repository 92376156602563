import React from "react";

function MissionVission() {
    return (
        // <section className="agr-section container">
        <section className="">
            <div className="about-container  mt-7">
                <div className="row justify-content-center mb-5 pb-2">
                    <div className=" col-md-6 text-center heading-section ">
                        <h3 className="fw-bolder fw-bold">
                            <span className="header text-info">Our Vision</span>
                        </h3>
                        <h2 className="mb-4"> Vision Statement</h2>
                        <div className="pb-2">
                            <p>
                                To be the leading hub for business development, innovation,
                                and digital transformation in Bono East, creating a future
                                where entrepreneurs and businesses have the tools and resources
                                they need to succeed and drive positive change.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="about-container mt-2">
                    <div className="row justify-content-center mb-5 pb-2">
                        <div className=" col-md-6 text-center heading-section ">
                            <h3 className="fw-bolder fw-bold">
                                <span className="header text-info">Our Mission</span>
                            </h3>
                            <h2 className="mb-4"> Mission Statement</h2>
                            <div className=" pb-5">
                                <p>
                                    Our mission is to foster entrepreneurship, drive innovation,
                                    and support business growth by offering expert guidance, essential
                                    skills development, and access to crucial resources. At Agrico Hub,
                                    we believe that with the right tools, knowledge, and support, businesses
                                    in Bono East and beyond can achieve sustainable success and contribute to
                                    local economic development.
                                    We believe in the power of innovation, skills development, and strategic
                                    support to unlock new opportunities for businesses to grow, scale, and succeed.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MissionVission;
