import React from "react";
import GHN from "../../images/partner-logos/ghn.png";
import GIZ from "../../images/partner-logos/giz.png";
import planGhana from "../../images/partner-logos/plan-gh.png";
import soronkoAcademy from "../../images/partner-logos/soronko-academy.png";
import mest from "../../images/partner-logos/mest.png";
import jobberMan from "../../images/partner-logos/jobberman.png";
import seGhana from "../../images/partner-logos/seghana.png";

export default function Partners() {
  return (
    <section className="partners-container">
      <div className="agr-section container">
        <div className="">
          <div className="row justify-content-center mb-5 pb-2">
            <div className="col-md-8 text-center heading-section">
              <h2 className="mb-4">Our Main Partners</h2>
            </div>
          </div>
          <div className="row justify-content-center mb-5 pb-2">
            <div className="image-wrapper">
              <ul className="">
                <li className="">
                  <img src={GHN} alt="ghana hubs network" srcset={GHN} />
                </li>
                <li>
                  <img src={GIZ} alt="ghana hubs network" srcset={GIZ} />
                </li>
                <li>
                  <img src={mest} alt="ghana hubs network" srcset={mest} />
                </li>
                <li>
                  <img
                    src={planGhana}
                    alt="ghana hubs network"
                    srcset={planGhana}
                  />
                </li>
                <li>
                  <img
                    src={jobberMan}
                    alt="ghana hubs network"
                    srcset={jobberMan}
                  />
                </li>
                <li>
                  <img
                    src={soronkoAcademy}
                    alt="ghana hubs network"
                    srcset={soronkoAcademy}
                  />
                </li>
                <li>
                  <img
                    src={seGhana}
                    alt="ghana hubs network"
                    srcset={seGhana}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
