import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import creditUnionLogo from "../../images/credit_union/credit_union.png";
import creditUnionDoc from "../../assets/doc/credit_union_brochure.pdf";
import Footer from "../Home/Footer";
import { ReadMore } from "@mui/icons-material";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// Important: Set the worker source for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function StartupCooperations() {
  const [openPdfReader, setOpenPdfReader] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div className="credit-union">
      <Header />
      <BreadCrump currentPage={"Credit Union"} />
      <div className="main-content container mt-5">
        <div className="row justify-content-center mt-5 mb-5 pb-2">
          <div className="col-md-8 text-center heading-section">
            <h2 className="fw-bold text-info">
              Startup Cooperative Credit Union
            </h2>
          </div>
        </div>
        <div className="credit-union-wrapper">
          <div className="grid inner-content">
            <img src={creditUnionLogo} alt="" className="credit-union-logo" />
            <div className="credit-union-info">
              <div className="header">
                <h2 className="title">Who We Are</h2>
              </div>
              <div className="text-content">
                <p>
                  <strong> Startups Co-operative Credit Union Ltd. </strong> is
                  a member-owned where individual members hold minimum shares.
                  Our commitment at Startups Co-operative Credit Union is
                  unwavering as we strive to bolster the entrepreneurial journey
                  of startups. We offer a suite of customized financial products
                  and services designed to address the unique financial
                  requirements of startups.
                  <br />
                  <br />
                  <strong>In addition to our core offerings, </strong>we go
                  beyond the conventional to foster success for our startups.
                  Through innovations, strategic investments, and valuable
                  partnerships, we aim to enhance the probability of success for
                  the startups we serve. At Startups Co-operative Credit Union,
                  your entrepreneurial dreams matter, and we're here to help you
                  achieve them.
                </p>

                <button
                  className="mb-5 reader-toggler"
                  onClick={() => setOpenPdfReader(!openPdfReader)}
                >
                  Read More
                  <span>
                    <ReadMore />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {openPdfReader && (
          <div className="document-reader">
            <div className="pdf-container">
              <Document
                file={creditUnionDoc}
                onLoadSuccess={onDocumentLoadSuccess}
                className="pdf-document"
              >
                <Page
                  pageNumber={pageNumber}
                  className="pdf-page"
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            </div>
            <div className="pdf-controls">
              <button
                onClick={previousPage}
                disabled={pageNumber <= 1}
                className="page-control"
              >
                Previous
              </button>
              <span className="page-info">
                Page {pageNumber} of {numPages}
              </span>
              <button
                onClick={nextPage}
                disabled={pageNumber >= numPages}
                className="page-control next-page"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default StartupCooperations;
