import React from "react";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";
import StandardImageList from "../../component/Global/abrafiImageGallery/imagegallery";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import TapasOutlinedIcon from "@mui/icons-material/TapasOutlined";
import EventSeatOutlinedIcon from "@mui/icons-material/EventSeatOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";

function AbStudio() {
  return (
    <section>
      <Header />
      <BreadCrump currentPage={"Abrafi Digital Studio"} />
      <div className="main-content container mt-5">
        <div className=" justify-content-center mt-5 mb-5">
          <div className=" text-center heading-section mt-5 mb-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Digital Photo Studio</span>
            </h3>
            <h2 className="fw-bolder">Abrafi Digital Studio</h2>
          </div>
          <div className="main-content grid mt-3">
            <div className="left-section">
              <div className="abrafi-main-content mb-4">
                <div className="">
                  <section>
                    <p>
                      <strong className="text-info">
                        At Abrafi Digital Studio,
                      </strong>{" "}
                      we transform moments into timeless memories. As a premier
                      photoshoot studio, we pride ourselves on capturing the
                      essence of your special occasions with creativity and
                      precision.
                    </p>
                  </section>

                  <section>
                    <h2 className="header text-info">Our Vision</h2>
                    <p>
                      Our vision is to be the go-to destination for individuals
                      and families seeking exceptional photography services. We
                      believe in the power of visual storytelling and aim to
                      provide our clients with stunning images that will be
                      cherished for generations.
                    </p>
                  </section>

                  <section>
                    <h2 className="header text-info">What Sets Us Apart</h2>
                    <ul className="m-0 p-0">
                      <li>
                        <strong>Professionalism:</strong> Our team of
                        experienced photographers is dedicated to delivering
                        professionalism at every step.
                      </li>
                      <li>
                        <strong>Creativity:</strong> At Abrafi Digital Studio,
                        creativity knows no bounds. We approach each photoshoot
                        with an artistic eye, ensuring that every frame tells a
                        unique story.
                      </li>
                      <li>
                        <strong>State-of-the-Art Facilities:</strong> Equipped
                        with state-of-the-art technology and a spacious studio,
                        we provide an environment that enhances the photography
                        experience.
                      </li>
                    </ul>
                  </section>

                  <div className="activities-wrapper mt-3">
                    <h3 className="header text-info">Services</h3>
                    <ul className="activity-list">
                      <li className="activity-list-item border-none">
                        <div className="icon">
                          <EventSeatOutlinedIcon />
                        </div>
                        <span>Portrait Photography</span>
                      </li>
                      <li className="activity-list-item border-none">
                        <div className="icon">
                          <TapasOutlinedIcon />
                        </div>
                        <span>Event Photography</span>
                      </li>
                      <li className="activity-list-item border-none">
                        <div className="icon">
                          <Diversity3OutlinedIcon />
                        </div>
                        <span>Product Photography</span>
                      </li>
                      <li className="activity-list-item border-none">
                        <div className="icon">
                          <MeetingRoomOutlinedIcon />
                        </div>
                        <span>Product Design</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-section">
              <div className="image-gallery-container">
                <StandardImageList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AbStudio;
