import visitToMCE from "../../../images/banner-slider__image/visit_to_mce.jpg";
import mediaBootCmp from "../../../images/projects/digital_media_bootcamp/dmb_1.jpg";
import gizVisitHub from "../../../images/banner-slider__image/giz_me_visit.jpg";
import escape_8 from "../../../images/banner-slider__image/escape_8.jpg";
import pretech2 from "../../../images/projects/pretech/pretech_2.jpg";
import Sixth from "../../../images/projects/pretech/pretech_5.jpg";
import Seventh from "../../../images/banner-slider__image/krobo_1.jpg";
import Eighth from "../../../images/projects/escape_program/escape_10.jpg";
import Ninth from "../../../images/banner-slider__image/digi-arts.jpg";
import Tenth from "../../../images/banner-slider__image/pretech_1.jpg";
import Escape_1 from "../../../images/banner-slider__image/escape_1.jpg";
import Escape_2 from "../../../images/banner-slider__image/escape_2.jpg";

export const ImageList = [
  {
    title: "Agrico Hub",
    description: "Igniting Passion For Creative Action",
    urls: visitToMCE,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: mediaBootCmp,
  },
  {
    title: "Agrico Hub",
    description: "Incubation, Digital Skills Training",
    urls: gizVisitHub,
  },
  {
    title: "Agrico Hub",
    description: "Software Development Solutions",
    urls: escape_8,
  },
  {
    title: "Loved by all",
    description:
      "Our name stand tall especially among the youth and rural women in the Region.",
    urls: pretech2,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Sixth,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Seventh,
  },

  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Eighth,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Ninth,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Tenth,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Escape_1,
  },
  {
    title: "Agrico Hub",
    description: "We are your Hub for Innovation, Business Coaching",
    urls: Escape_2,
  },
];
