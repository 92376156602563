import React from "react";
import { Link } from "react-router-dom";
import breadcrumpBg from "../../../images/background/bg.jpg";
import { useLocation } from "react-router-dom";
//
function BreadCrump({ currentPage }) {
  const location = useLocation();
  return (
    <section
      className="hero-wrap hero-wrap-2"
      style={
        {
          // backgroundImage: `url(${breadcrumpBg})`,
        }
      }
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text align-items-center justify-content-center">
          <div className="col-md-9 text-center crump-content">
            {location.pathname.includes("/blog/") ? (
              <></>
            ) : (
              <>
                <h1 className="mb-2 bread">{currentPage}</h1>
              </>
            )}
            <p className="breadcrumbs ml-2">
              <span className="mr-2">
                <Link to="/">
                  Home <i className="ion-ios-arrow-forward"></i>
                </Link>
              </span>{" "}
              <span style={{ cursor: "pointer" }}>
                {currentPage} <span className="icon-arrow-forward"></span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BreadCrump;
