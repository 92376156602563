import React, { useState } from "react";
import { ChevronLeft, ChevronRight, InfoIcon } from "lucide-react";
import {
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";

const ProjectList = ({ projectData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedYear, setSelectedYear] = useState("all");
  const projectsPerPage = selectedYear === "all" ? 3 : 1;

  // Extract unique years from project data
  const years = [
    "all",
    ...new Set(
      projectData
        .map((project) => {
          const yearMatch = project.program.match(/\((\d{4})/);
          return yearMatch ? yearMatch[1] : null;
        })
        .filter(Boolean)
    ),
  ];

  // Filter projects based on selected year
  const filteredProjects =
    selectedYear === "all"
      ? projectData
      : projectData.filter((project) => project.program.includes(selectedYear));

  // Calculate pagination
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  // Reset to first page when year changes
  const handleYearChange = (year) => {
    setSelectedYear(year);
    setCurrentPage(1);
  };

  return (
    <div>
      {/* Filter Dropdown */}
      <div className="project-filter">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Project Year</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedYear}
            label="Project Year"
            onChange={(e) => handleYearChange(e.target.value)}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year === "all" ? "All Years" : year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Project List */}
      {currentProjects.map((data, index) => (
        <div key={index}>
          <div className="project-content__item">
            <div className="project-text">
              <article className="prj-list__wrapper">
                <h3 className="project-list__header">{data.program}</h3>
                <p className="project-info__text">{data.text}</p>
              </article>
            </div>
            <div className="image">
              <ImageList sx={{ width: 500, height: 450 }}>
                {data.itemData.map((item, imgIndex) => (
                  <ImageListItem key={imgIndex}>
                    <img src={item.img} alt={item.title} loading="lazy" />
                    <ImageListItemBar
                      title={item.title}
                      actionIcon={
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255)" }}
                          aria-label={`info about ${item.title}`}
                        >
                          <InfoIcon />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          </div>
          <hr className="mb-3 hr" />
        </div>
      ))}
      <div className="pagination">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, newPage) => setCurrentPage(newPage)}
          showFirstButton
          showLastButton
        />
      </div>
    </div>
  );
};

export default ProjectList;
