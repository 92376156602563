import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/header";
import BreadCrump from "../../component/Global/Breadcrump";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { IconButton, ImageListItemBar } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { projectData } from "./projectData";
import ProjectGalleryHybrid from "./ProjectGalleryHybrid";
import ProjectList from "./ProjectList";

function Project() {
  return (
    <div className="project">
      <Header />
      <BreadCrump currentPage={"Projects"} />
      <section className="project-container">
        <div className="container">
          <div className=" text-center heading-section mt-5 mb-5">
            <h3 className="fw-bolder fw-bold">
              <span className="header text-info">Projects</span>
            </h3>
            <h2 className="mb-4">Most Popular Projects</h2>
          </div>
          <div className="project-content">
            <ProjectList projectData={projectData} />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Project;

// re-usable component
function TitlebarImageList({ itemData }) {
  return (
    <ImageList sx={{ width: 500, height: 450 }}>
      {itemData.map((item) => {
        return (
          <ImageListItem>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.title}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                  aria-label={`info about ${item.title}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
}

const ProjectTextList = ({ item, index }) => {
  return (
    <article className="prj-list__wrapper">
      <ul>
        <li key={index}>
          <h3 className="project-list__header">{item.program}</h3>
          <p className="project-info__text">{item.text}</p>
        </li>
      </ul>
    </article>
  );
};

// const ProjectList = () => {
// return (
// <div>
//   {projectData.map((data, index) => {
//     return (
//       <>
//         <div key={index}>
//           <div className="project-content__item">
//             <div className="project-text">
//               <ProjectTextList index={index} item={data} />
//             </div>
//             <div className="image">
//               <TitlebarImageList itemData={data.itemData} key={index} />
//             </div>
//           </div>
//           <hr className="mb-3 hr" />
//         </div>
//       </>
//     );
//   })}
// </div>
// );
// };
